.App {
  font-family: sans-serif;
}

.slider-arrow {
  position: absolute;
  height: 100%;
  width: 100%;
}

.arrow-btn {
  top: 30%;
  z-index: 1;
}
.next {
  float: right;
}

.next {
  right: -40px;
}
.prev {
  left: -40px;
}

@media only screen and (max-width: 500px) {
  .next {
    right: -37px;
  }
  .prev {
    left: -37px;
  }
}

